<template>
  <div>
    <section class="">
      <b-row>
        <b-col cols="12">
          <div class="text-center p-5 mt-0 mr-5 ml-5 mb-0 pb-0">
            <span class="section-title">BLOGS</span>
            <h3 class="section-subtitle pt-2 section3-title">
              Write a Blog Post
            </h3>
          </div>
        </b-col>
      </b-row>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="p-5 pt-0 mt-0 md:ml-15 md:mr-15"
      >
        <v-container class="border-2-color rounded-lg">
          <v-row>
            <v-col cols="12" class="text-center" md="6">
              <img
                class=""
                height="550"
                width="550"
                src="/image/blog-image.png"
                alt=""
              />
            </v-col>
            <v-col>
              <v-row class="text-center">
                <h5>Blog Title :</h5>
              </v-row>
              <v-row>
                <v-col class="pb-0">
                  <v-text-field
                    v-model="title"
                    outlined
                    placeholder="Title"
                    dense
                    :rules="[(v) => !!v || 'Blog Title is required']"
                    v-on:keydown="successfull = false"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="text-center">
                <h5>Blog Image</h5>
              </v-row>
              <v-row>
                <v-col>
                  <input
                    class="form-control bg-white"
                    name="Banner"
                    @change="onFileChanged"
                    type="file"
                    accept="image/*"
                    v-on:change="successfull = false"
                  />
                </v-col>
              </v-row>
              <v-row class="text-center pt-4">
                <h5>Short description</h5>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="short_desc"
                    placeholder="Short description"
                    outlined
                    :rules="[(v) => !!v || 'Description is required']"
                    dense
                    v-on:keydown="successfull = false"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="text-center">
                <h5>Select Date</h5>
              </v-row>
              <v-row>
                <v-col>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        placeholder="Select Date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        :rules="[(v) => !!v || 'Date is required']"
                        v-on:keydown="successfull = false"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="text-center">
                <h5>Add Blog File</h5>
              </v-row>
              <v-row>
                <v-col>
                  <input
                    class="form-control bg-white"
                    id="formFile"
                    :v-model="file"
                    accept=".pdf,.doc"
                    label="File input"
                    v-on:change="onAddBlogFile"
                    type="file"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <b-button
                    variant="dark"
                    class="ma-2 white--text"
                    @click="blogadd()"
                  >
                    Send Request
                  </b-button>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div
                    class="mt-3 alert alert-success alert-dismissible fade show"
                    v-if="successfull"
                  >
                    <strong>Success!</strong> Your Question has been sent
                    successfully, Please Wait For Approval !!!!
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </section>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "Addblog",
  data() {
    return {
      file: null,
      title: null,
      date: null,
      short_desc: null,
      banner: null,
      selectedFile1: null,
      selectedFile2: null,
      attachment: null,
      user_id: null,
      dateFormatted: null,
      menu: null,
      valid: null,
      successfull: false,
    };
  },
  created() {},
  methods: {
    ...mapActions(["getaddblog"]),
    onFileChanged(e) {
      this.selectedFile1 = e.target.files[0];
      const reader = new FileReader();

      reader.readAsDataURL(e.target.files[0]);

      // do something
    },
    onAddBlogFile(e) {
      // if(e.target.files[0]['type']==='image/jpeg')
      this.selectedFile2 = e.target.files[0];
      const reader = new FileReader();

      reader.readAsDataURL(e.target.files[0]);
    },

    blogadd() {
     
      if (this.$refs.form.validate()) {
          this.user_id= localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):"";
          if(this.user_id)
          {

        
        this.getaddblog({
          title: this.title,
          postdate: this.date,
          short_desc: this.short_desc,
          banner: this.selectedFile1,
          attachment: this.selectedFile2,
          user_id: this.user_id.id,
        }).then(() => {
          //this.$router.push("/blog");
          this.successfull = true;
          this.file = null;
          this.title = null;
          this.date = null;
          this.short_desc = null;
          this.banner = null;
          this.selectedFile1 = null;
          this.selectedFile2 = null;
          this.attachment = null;
          this.dateFormatted = null;

          console.log(this.file);
        });
          }
        this.$refs.form.reset();
      }
    },
  },
};
</script>
